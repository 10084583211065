import { useRef, useEffect } from "react";

const Video = ({ selectedVideoIndex, index, videoUrl }) => {
  const videoRef = useRef(null);
  videoRef.current && (videoRef.current.currentTime = 0);

  useEffect(() => {
    if (videoRef.current && selectedVideoIndex === index) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [selectedVideoIndex, index]);

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      loop
      style={{
        width: "100%",
        height: selectedVideoIndex === index ? "450px" : "350px",
        transition: "height 0.5s ease",
      }}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
