import "./index.css";

const VerticalContent = ({ slides, selectedIndex, onClickSlide }) => {
  return (
    <div className="embla__container__vertical">
      {slides.map((slide, index) => (
        <div className="embla__slide__vertical" key={slide.title}>
          <div className="embla__slide__number__vertical">
            {slide.image ? (
              <div
                style={{
                  backgroundImage: `url(${slide.image})`,
                  width: selectedIndex !== index && "500px",
                  transition: "width 0.3s ease",
                  cursor: "pointer",
                }}
                className="embla__slide__content__vertical"
                onClick={() => onClickSlide(slide.key)}
              ></div>
            ) : (
              index + 1
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VerticalContent;
