import EmblaCarousel from "components/carousel";

const TechnologyCarousel = () => {
  const slides = [
    {
      title: "HEAD-UP DISPLAY",
      video: "https://content.d3ck.cc/cupra/hud.mp4",
      description:
        "La información en tiempo real se proyecta en el parabrisas.",
    },
    {
      title: "ASISTENTE APARCAMIENTO",
      video: "https://content.d3ck.cc/cupra/park.mp4",
      description:
        "Utiliza el sistema Intelligent Park Assist para espacios estrechos y Trained Park Assist, que memorizará tus maniobras frecuentes, para aparcar habitualmente. ",
    },
    {
      title: "ASISTENTE DE VIAJE",
      video: "https://content.d3ck.cc/cupra/travel.mp4",
      description:
        "No pierdas la concentración, gracias a la tecnología de mantenimiento de carril, el Control de crucero adaptativo y los cambios de carril autónomos.",
    },
  ];

  return <EmblaCarousel slides={slides} options={{ loop: true }} />;
};

export default TechnologyCarousel;
