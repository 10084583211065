const Image = ({ imageUrl, selectedImageIndex, index }) => {
  return (
    <img
      src={imageUrl}
      alt=""
      style={{
        width: "100%",
        height: selectedImageIndex === index ? "400px" : "250px",
        transition: "height 0.3s ease",
        objectFit: "cover",
      }}
    />
  );
};

export default Image;
