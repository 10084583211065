import Image from "./image";
import "./index.css";
import Video from "./video";

const HorizontalContent = ({ slides, selectedIndex }) => {
  return (
    <div className="embla__container">
      {slides.map((slide, index) => (
        <div className="embla__slide" key={slide.title}>
          <div className="embla__slide__number">
            {slide.video ? (
              <Video
                selectedVideoIndex={selectedIndex}
                index={index}
                videoUrl={slide.video}
              />
            ) : slide.image ? (
              <Image
                imageUrl={slide.image}
                selectedImageIndex={selectedIndex}
                index={index}
              />
            ) : (
              index + 1
            )}
          </div>
          <div
            className="embla__slide__text"
            style={{ opacity: selectedIndex === index ? 1 : 0 }}
          >
            <div className="slide__title">{slide.title}</div>
            <div className="slide__description">{slide.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalContent;
