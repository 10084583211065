import EmblaCarousel from "components/carousel";
import { useState } from "react";
import "./index.css";

const DesignCarousel = () => {
  const [activeCarousel, setActive] = useState("design");

  const designCarouselSlides = [
    {
      title: "LLANTAS",
      key: "rims",
      image: "https://content.d3ck.cc/cupra/Crops-07.jpg",
    },
    {
      title: "COLOR",
      key: "colors",
      image: "https://content.d3ck.cc/cupra/Crops-06.jpg",
    },
    {
      title: "TAPICERÍA",
      key: "upholstery",
      image: "https://content.d3ck.cc/cupra/Crops-08.jpg",
    },
  ];

  const colorCarouselSlides = [
    {
      title: "AZUL COSMOS | Metalizado",
      image: "https://content.d3ck.cc/cupra/Crops-09.jpg",
    },
    {
      title: "DARK VOID | Metalizado especial",
      image: "https://content.d3ck.cc/cupra/Crops-10.jpg",
    },
    {
      title: "GRIS GRAPHENE | Metálico especial",
      image: "https://content.d3ck.cc/cupra/Crops-14.jpg",
    },
    {
      title: "BLANCO GLACIAL | Metalizado",
      image: "https://content.d3ck.cc/cupra/Crops-13.jpg",
    },
    {
      title: "AZUL FIORD | Suave",
      image: "https://content.d3ck.cc/cupra/Crops-12.jpg",
    },
    {
      title: "AMERICA'S CUP GRIS ENCELADUS MATE | Mate",
      image: "https://content.d3ck.cc/cupra/Crops-11.jpg",
    },
  ];

  const rimCarouselSlides = [
    {
      title: "AMERICA'S CUP DE 50,8 CM (20”)",
      image: "https://content.d3ck.cc/cupra/Crops-15.jpg",
    },
    {
      title: "HADRON COPPER DE 50,8 CM (20”)",
      image: "https://content.d3ck.cc/cupra/Crops-17.jpg",
    },
    {
      title: "HADRON DE 50,8 CM (20”)",
      image: "https://content.d3ck.cc/cupra/Crops-18.jpg",
    },
    {
      title: "GRAVITY DE 50,8 CM (20”)",
      image: "https://content.d3ck.cc/cupra/Crops-16.jpg",
    },
  ];

  const upholsteryCarouselSlides = [
    {
      title: "AMERICA'S CUP",
      image: "https://content.d3ck.cc/cupra/Crops-19.jpg",
    },
    {
      title: "HIGH CANYON",
      image: "https://content.d3ck.cc/cupra/Crops-21.jpg",
    },
    {
      title: "MOON LIGHT",
      image: "https://content.d3ck.cc/cupra/Crops-22.jpg",
    },
    {
      title: "DEEP OCEAN",
      image: "https://content.d3ck.cc/cupra/Crops-20.jpg",
    },
  ];

  let carouselSlides = designCarouselSlides;
  let carouselOptions = { axis: "x", loop: true };

  switch (activeCarousel) {
    case "design":
      carouselSlides = designCarouselSlides;
      carouselOptions = { axis: "y", startIndex: 1, loop: false };
      break;
    case "colors":
      carouselSlides = colorCarouselSlides;
      carouselOptions = { axis: "x", loop: true };
      break;
    case "rims":
      carouselSlides = rimCarouselSlides;
      carouselOptions = { axis: "x", loop: true };
      break;
    case "upholstery":
      carouselSlides = upholsteryCarouselSlides;
      carouselOptions = { axis: "x", loop: true };
      break;
    default:
      carouselSlides = designCarouselSlides;
      carouselOptions = { axis: "y", startIndex: 1, loop: false };
      break;
  }

  return (
    <div className="design-carousel-container">
      <EmblaCarousel
        slides={carouselSlides}
        options={carouselOptions}
        onClickSlide={(name) => setActive(name)}
      />
      {activeCarousel !== "design" && (
        <div className="go-back-action">
          <button
            className="go-back-button"
            onClick={() => setActive("design")}
          >
            ATRÁS
          </button>
        </div>
      )}
    </div>
  );
};

export default DesignCarousel;
