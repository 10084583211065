import "./index.css";

const HorizontalContent = ({ slides, selectedIndex }) => {
  return (
    <div className="embla__container">
      {slides.map((slide, index) => (
        <div className="embla__slide" key={slide.title}>
          <div className="embla__slide__number">
            {slide.video ? (
              <video
                autoPlay
                muted
                loop
                style={{
                  width: "100%",
                  height: selectedIndex === index ? "400px" : "300px",
                  transition: "height 0.5s ease",
                }}
              >
                <source src={slide.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : slide.image ? (
              <img
                src={slide.image}
                alt=""
                style={{
                  width: "100%",
                  height: selectedIndex === index ? "400px" : "250px",
                  transition: "height 0.3s ease",
                  objectFit: "cover",
                }}
              />
            ) : (
              index + 1
            )}
          </div>
          <div
            className="embla__slide__text"
            style={{ opacity: selectedIndex === index ? 1 : 0 }}
          >
            <div className="slide__title">{slide.title}</div>
            <div className="slide__description">{slide.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalContent;
