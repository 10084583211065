import { addSrcImage } from "helpers";

function getCameraDimensions({ rotation, mirror, canvas, camera }) {
  if (rotation === 0) {
    const factor = canvas.height / camera.videoHeight;
    const increment = factor - 1;

    return {
      width: camera.videoWidth * factor,
      height: canvas.height,
      dx: mirror
        ? -camera.videoWidth * increment
        : (-camera.videoWidth * increment) / 2,
      dy: 0,
    };
  }

  const factor = canvas.height / camera.videoWidth;

  if (rotation === 90) {
    return {
      width: canvas.height,
      height: camera.videoHeight * factor,
      dx: mirror ? -canvas.height : 0,
      dy: -canvas.width,
    };
  }

  return {
    width: canvas.height,
    height: camera.videoHeight * factor,
    dx: mirror ? 0 : -canvas.height,
    dy: 0,
  };
}

function getImagesByType(type) {
  switch (type) {
    default:
      return {
        watermarkImg: "ADD_IMAGE_HERE",
        backgroundImg: "ADD_IMAGE_HERE",
        midgroundImg: "ADD_IMAGE_HERE",
        foregroundImg: "ADD_IMAGE_HERE",
        handImg: "ADD_IMAGE_HERE",
      };
  }
}

function setMirror(canvasCtx) {
  canvasCtx.scale(-1, 1);
}

function rotateCamera(canvasCtx, rotation) {
  canvasCtx.rotate((rotation * Math.PI) / 180);
}

function drawBackgroundLayer(backImage, canvas) {
  const canvasCtx = canvas.getContext("2d");

  canvasCtx.drawImage(backImage, 0, 0, canvas.width, canvas.height);
}

function drawHandLayer({ camera, canvas, handImage, opacity, config }) {
  const { rotation = -90, mirror = true } = config;
  const canvasCtx = canvas.getContext("2d");
  const handLayer = document.createElement("canvas");

  handLayer.width = canvas.width;
  handLayer.height = canvas.height;

  const handLayerctx = handLayer.getContext("2d");

  handLayerctx.drawImage(handImage, 0, 0, canvas.width, canvas.height);
  handLayerctx.globalCompositeOperation = "source-atop";

  const { width, height, dx, dy } = getCameraDimensions({
    rotation,
    mirror,
    canvas,
    camera,
  });

  if (rotation) rotateCamera(handLayerctx, rotation);
  if (mirror) setMirror(handLayerctx);

  canvasCtx.globalAlpha = opacity;
  handLayerctx.drawImage(camera, dx, dy, width, height);
  canvasCtx.drawImage(handLayer, 0, 0);
}

function drawMidgroundLayer(midImage, canvas) {
  const canvasCtx = canvas.getContext("2d");

  canvasCtx.drawImage(midImage, 0, 0, canvas.width, canvas.height);
}

function drawWatermarkLayer(canvas, watermarkImage) {
  const canvasCtx = canvas.getContext("2d");

  canvasCtx.drawImage(watermarkImage, 0, 0, canvas.width, canvas.height);
}

export async function drawCaptureCanvas(camera, canvas, config) {
  const { watermarkImg, backgroundImg, handImg, midgroundImg } =
    getImagesByType(config.type);
  const backImage = await addSrcImage(backgroundImg);
  const midImage = await addSrcImage(midgroundImg);
  const handImage = await addSrcImage(handImg);
  const watermarkImage = await addSrcImage(watermarkImg);

  drawBackgroundLayer(backImage, canvas);
  drawHandLayer({
    camera,
    canvas,
    handImage,
    opacity: 1,
    config,
  });
  drawMidgroundLayer(midImage, canvas);
  drawWatermarkLayer(canvas, watermarkImage);
}

function drawCameraLayer(camera, canvas, config) {
  const { rotation = -90, mirror = true } = config;
  const canvasCtx = canvas.getContext("2d");
  const { width, height, dx, dy } = getCameraDimensions({
    rotation,
    mirror,
    canvas,
    camera,
  });

  canvasCtx.save();

  if (rotation) rotateCamera(canvasCtx, rotation);
  if (mirror) setMirror(canvasCtx);

  canvasCtx.globalAlpha = 0.2;
  canvasCtx.drawImage(camera, dx, dy, width, height);
  canvasCtx.restore();
}

function drawForegroundLayer(canvas, foreImage) {
  const canvasCtx = canvas.getContext("2d");

  canvasCtx.save();
  canvasCtx.globalAlpha = 1;
  canvasCtx.drawImage(foreImage, 0, 0, canvas.width, canvas.height);
  canvasCtx.restore();
}

export async function drawForegroundCanvas(camera, canvas, config) {
  const { foregroundImg, handImg } = getImagesByType(config.type);
  const foreImage = await addSrcImage(foregroundImg);
  const handImage = await addSrcImage(handImg);

  drawForegroundLayer(canvas, foreImage);
  drawCameraLayer(camera, canvas, config);
  drawHandLayer({
    camera,
    canvas,
    handImage,
    opacity: 0.6,
    config,
  });
}
