import TechnologyCarousel from "views/cupra-campaign/carousel/technology-carousel";

import "./CarouselElement.css";
import DesignCarousel from "views/cupra-campaign/carousel/design-carousel";

function CarouselElement({ element = {} }) {
  const { config = {} } = element;

  const { instance } = config;

  let CarouselContent = null;

  switch (instance) {
    case "tech":
      CarouselContent = <TechnologyCarousel />;
      break;
    case "design":
      CarouselContent = <DesignCarousel />;
      break;
    default:
      break;
  }

  return <div>{CarouselContent}</div>;
}

export default CarouselElement;
