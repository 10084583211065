import { getTextContent } from "../helpers";

import "./ButtonElement.css";

function ButtonElement({ element, setActiveSlide, creativeState = {} }) {
  const { style, targetSlide, config = {} } = element;

  const { state = {} } = config;

  const { disabled: stateDisabled } = state;

  const disabled = stateDisabled !== undefined && !creativeState[stateDisabled];

  const text = getTextContent(element);

  function onClick(targetSlide) {
    if (Number.isInteger(targetSlide)) {
      return function () {
        setActiveSlide(targetSlide);
      };
    }
  }

  return (
    <button
      className="button"
      style={{ ...style, position: "static" }}
      onClick={onClick(targetSlide)}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default ButtonElement;
