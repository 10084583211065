import React from "react";
import { DotButton } from "./dot-button";
import { PrevButton } from "./arrow-buttons/prev-button";
import { NextButton } from "./arrow-buttons/next-button";
import { useDotButton } from "./hooks/use-dot-button";
import { usePrevNextButtons } from "./hooks/use-prev-next-buttons";
import useEmblaCarousel from "embla-carousel-react";
import "./index.css";
import HorizontalContent from "./content/horizontal";
import VerticalContent from "./content/vertical";

const EmblaCarousel = ({ slides, options, onClickSlide }) => {
  const { axis = "x" } = options;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className={axis === "x" ? "embla__horizontal" : "embla__vertical"}>
      <div className="embla__viewport" ref={emblaRef}>
        {axis === "x" ? (
          <HorizontalContent slides={slides} selectedIndex={selectedIndex} />
        ) : (
          <VerticalContent
            slides={slides}
            selectedIndex={selectedIndex}
            onClickSlide={onClickSlide}
          />
        )}
      </div>
      {axis === "x" && (
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </div>
      )}
      <PrevButton
        onClick={onPrevButtonClick}
        disabled={prevBtnDisabled}
        axis={axis}
      />
      <NextButton
        onClick={onNextButtonClick}
        disabled={nextBtnDisabled}
        axis={axis}
      />
    </section>
  );
};

export default EmblaCarousel;
